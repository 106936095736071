<template>
  <iframe
    :src="blobUrl"
    style="width: 100%; height: 100dvh"
    ref="iframeRef"
    @load="printIframe"
  />
</template>

<script>
import jsPDF from "jspdf";
import invoiceHelper, {
  printInvoiceList,
} from "../../../helpers/invoice-helper";
import { bulkUpdateInvoice } from "../../../services/api/invoice";

export default {
  data() {
    return {
      blobUrl: null,
      invoiceIDs: [],
      startCode: null,
      endCode: null,
      invoice_codes: [],
      paramsType: "",
    };
  },
  mounted() {
    this.paramsType = sessionStorage.getItem("param_type");
    if (this.paramsType === "id") {
      this.invoiceIDs = JSON.parse(sessionStorage.getItem("invoice_id"));
    } else if (this.paramsType === "code,id") {
      this.startCode = sessionStorage.getItem("start_code");
      this.endCode = sessionStorage.getItem("end_code");
      this.invoiceIDs = JSON.parse(sessionStorage.getItem("invoice_id"));
    } else {
      this.startCode = sessionStorage.getItem("start_code");
      this.endCode = sessionStorage.getItem("end_code");
    }

    const isBillingList = sessionStorage.getItem("is_billing_list");

    if (isBillingList === "true") {
      console.log("printing billing list");
      this.printBillingList();
    } else {
      console.log("printing pdf");
      this.printPDF();
    }

    // this.$refs.iframeRef.contentWindow.print();
  },
  beforeDestroy() {
    URL.revokeObjectURL(this.blobUrl);
    sessionStorage.removeItem("param_type");
    if (this.paramsType === "id") {
      sessionStorage.removeItem("invoice_id");
    } else if (this.paramsType === "code,id") {
      sessionStorage.removeItem("start_code");
      sessionStorage.removeItem("end_code");
      sessionStorage.removeItem("invoice_id");
    } else {
      sessionStorage.removeItem("start_code");
      sessionStorage.removeItem("end_code");
    }
    sessionStorage.removeItem("isBillingList");
  },
  methods: {
    async printBillingList() {
      try {
        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        const url = await printInvoiceList(
          pdf,
          2.6,
          pageWidth,
          pageHeight,
          padding,
          this.startCode,
          this.endCode,
          this.invoiceIDs
        );

        this.blobUrl = url;
        // this.$refs.iframeRef.contentWindow.print();
      } catch (e) {
        console.log(e);
      }
    },
    async printPDF() {
      try {
        const padding = 1;
        const height = 37.1856;
        const width = 28.6512;
        const pageWidth = width - padding * 2;
        const pageHeight = height - padding * 2;

        const pdf = new jsPDF({
          unit: "cm",
          format: [width, height],
        });

        // let cursorY = 0;

        pdf.setFontSize(13);
        pdf.setFont("courier", "bold");
        // const invoiceIDs = this.$store.getters["arInvoice/getselectedInvoicesIds"];

        console.log("invoice ids", this.invoiceIDs);

        const url = await invoiceHelper.printPDF(
          pdf,
          null,
          true,
          pageWidth,
          pageHeight,
          padding,
          this.startCode,
          this.endCode,
          this.invoiceIDs
        );

        await this.updatePrintBulk(this.invoiceIDs, this.startCode, this.endCode);
        this.blobUrl = url;
      } catch (e) {
        console.log(e);
      }
    },
    async updatePrintBulk(ids, starCode, endCode) {
      try {
        this.$vs.loading();
        const resp = await bulkUpdateInvoice(ids, starCode, endCode);
        if (resp.code > 299) {
          throw new Error("Failed to update print count");
        }
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
        throw e;
      }
    },
    printIframe() {
      if (!this.blobUrl) {
        return;
      }
      console.log("loaded");
      this.$refs.iframeRef.contentWindow.print();
    },
  },
};
</script>
